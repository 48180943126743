var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4J6aGEqQqWVFITietC_h5"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs"

// Ignores error connected to how different browsers cancel requests
const ignoreErrors = [
    "TypeError: NetworkError when attempting to fetch resource.",
    "TypeError: cancelled",
    "TypeError: Cancelled",
    "message: cancelled",
    "cancelled",
    "TypeError: avbrutt",
    "avbrutt"
]

Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    autoSessionTracking: false,
    integrations: [new Sentry.BrowserTracing()],
    ignoreErrors,

    beforeSend(event) {
        let referer = event.request.headers.Referer
        let url = event.request.url

        if (event.message === "Page not found" && referer && url !== referer)
            return event
    }
})
